<template>
    <vx-tooltip v-if="params.value === 'warning'" text="Alerta" position="bottom" delay=".3s" class="flex flex-row justify-center items-center w-full mt-3">
        <!-- <feather-icon icon='AlertTriangleIcon' svgClasses='w-6' class='bg-warning border-grey-light rounded-full pr-2 pl-2 pb-2 pt-1 text-white' /> -->
        <img src="@/assets/svg/warning.svg" class="w-8 h-8" />
    </vx-tooltip>
    <vx-tooltip v-else-if="params.value === 'critical'" text="Erro critico" position="bottom" delay=".3s" class="flex flex-row justify-center items-center w-full mt-3">
        <!-- <feather-icon icon='XOctagonIcon' svgClasses='w-12 h-12' class='bg-red border-grey-light rounded-full text-white' /> -->
        <img src="@/assets/svg/error.svg" class="w-8 h-8" />
    </vx-tooltip>
</template>

<script>
export default {
  name: "MachineCategoryFormat",
  data: () => ({
    //TODO0
  }),
  methods: {
  },
};
</script>

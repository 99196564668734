<template>
  <div class="flex flex-row justify-center items-end mt-6">
  <vs-chip class="ag-grid-cell-chip">
    <span><strong>{{ translateModel(params.value) }}</strong></span>
  </vs-chip>
  </div>
</template>

<script>
export default {
  name: "MachineModelTranslate",
  methods: {
    translateModel(value) {
      switch (value) {
        case 0:
          return "STRIKE";
        case 1:
          return "ONE";
        case 2:
          return "Catamoeda";
        case 3:
          return "FIT";
      }
    },
  },
};
</script>

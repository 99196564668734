<template>
    <vx-tooltip :text="params.value" position="bottom" delay=".3s" class="flex flex-row justify-center items-center w-full mt-1">
        <span>{{ params.value }}</span>
    </vx-tooltip>
</template>

<script>
export default {
  name: "MachineMessageType",
  data: () => ({
    //TODO0
  }),
  methods: {
  },
};
</script>

<template>
    <span>{{ parseDateFormat(params.value) }}</span>
    
</template>

<script>
import moment from 'moment'
export default {
  name: "MachineDateFormat",
  data: () => ({
    //TODO
  }),
  methods: {
    parseDateFormat(value) {
      //value format is 2023-03-08 22:12:02, parse to 3 days and 2 hours ago for example.
      let diff =  moment.duration(moment(value).diff(moment.now()));
      const asDays = diff.asDays();
      const asHours = diff.asHours();
      const asMinutes = diff.asMinutes();
      const asSeconds = diff.asSeconds();
      if(Math.abs(asDays) > 30){
        return moment(value).format('YYYY-MM-DD HH:mm:ss');
      } else if(Math.abs(asDays) > 1){
        return Math.abs(Math.round(asDays)) + ' days ago';
      } else if(Math.abs(asHours) > 1){
        return Math.abs(Math.round(asHours)) + ' hours ago';
      } else if(Math.abs(asMinutes) > 1){
        return Math.abs(Math.round(asMinutes)) + ' minutes ago';
      } else if(Math.abs(asSeconds) > 1){
        return Math.abs(Math.round(asSeconds)) + ' seconds ago';
      } else{
        return 'Just now';
      }
    },
  },
};
</script>

<template lang="html">
  <div id="page-list">
    <div id="list-content" class="vx-card p-6 vs-con-loading__container">
      <vs-prompt
        :title="$t('actions.exportData')"
        class="export-options"
        @cancel="clearFields"
        @accept="exportToExcel"
        :accept-text="$t('actions.export')"
        :cancel-text="$t('actions.cancel')"
        @close="clearFields"
        :active.sync="activePrompt"
      >
        <vs-input
          v-model="fileName"
          :placeholder="$t('actions.enterFilename')"
          class="w-full"
        />
        <v-select
          v-model="selectedFormat"
          :clearable="false"
          :options="formats"
          class="my-4"
        />
      </vs-prompt>

      <div class="flex flex-wrap justify-between items-center">

        <div class="vx-col md:w-1/4" v-if="$store.state.AppActiveUser.type > 0">
          <label class="vs-input--label">{{ $t("machine.company") }}</label>
          <v-select
            v-model="companyFilter"
            :clearable="false"
            :options="companyOptions"
            name="companyFilter"
            :searchable="false"
            :reduce="x => x.id"
            label="name"
            :data-vv-as="$t('machine.company')"
          />
        </div>

        <div
          class="flex flex-wrap items-center justify-between ag-grid-table-actions-right"
        >
          <vs-input
            class="mb-4 md:mb-0 mr-4"
            v-model="searchQuery"
            @input="updateSearchQuery"
            :label-placeholder="$t('navbar.search')"
          />
          <vs-button class="mb-4 md:mb-0 mt-5" @click="activePrompt = true">{{
            $t("actions.export")
          }}</vs-button>
        </div>
      </div>

      <ag-grid-vue
        ref="agGridTable"
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table text-center"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="data"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl"
      >
      </ag-grid-vue>
            <!-- if dont have any values in grid, show label -->
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />


    </div>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
import i18n from "@/i18n/i18n";
import { getAgGridTranslation } from "@/utils/utils";
import vSelect from "vue-select";

// Cell Renderer
import MachineModelTranslate from "./cell-renderer/MachineModelTranslate.vue";
import MachineDateFormat from "./cell-renderer/MachineDateFormat.vue";
import MachineCategoryFormat from "./cell-renderer/MachineCategoryFormat.vue";
import MachineMessageType from "./cell-renderer/MachineMessageType.vue";

export default {
  components: {
    AgGridVue,
    vSelect,
    MachineModelTranslate,
    MachineDateFormat,
    MachineCategoryFormat,
    MachineMessageType
  },
  data: () => ({
    data: null,
    dataRaw: null,
    status: true,
    searchQuery: "",
    companyOptions: [
      {
        id: "",
        name: i18n.t("common.all")
      }
    ],
    companyFilter: "",

    //Export2Excel
    activePrompt: false,
    fileName: "",
    formats: ["xlsx", "csv", "txt"],
    cellAutoWidth: true,
    selectedFormat: "xlsx",
    headerTitle: [
      i18n.t("common.date"),
      i18n.t("common.category"),
      i18n.t("company.trade"),
      i18n.t("machine.product"),
      i18n.t("machine.serial_number"),
      i18n.t("common.message")
    ],
    headerVal: [
      "log_date",
      "log_category",
      "nomeFantasia",
      "type",
      "serial_number",
      "log_message"
    ],

    // AgGrid
    gridOptions: {
      localeTextFunc: function(key, defaultValue) {
        return getAgGridTranslation(key, defaultValue);
      },
      suppressCellSelection: true
    },
    maxPageNumbers: 7,
    gridApi: null,
    defaultColDef: {
      sortable: true,
      resizable: true,
      suppressMenu: true,
      floatingFilter: true
    },
    columnDefs: [
    {
        headerName: i18n.t("common.category"),
        field: "log_category",
        filter: true,
        width: 100,
        cellRendererFramework: "MachineCategoryFormat",
      },
      {
        headerName: i18n.t("common.date"),
        field: "log_date",
        width: 200,
        suppressSizeToFit: true,
        cellRendererFramework: "MachineDateFormat"
      },
      {
        headerName: i18n.t("company.trade"),
        field: "nomeFantasia",
        width: 150,
        filter: true
      },
      {
        headerName: i18n.t("machine.product"),
        field: "type",
        filter: true,
        width: 100,
        cellRendererFramework: "MachineModelTranslate"
      },
      {
        headerName: i18n.t("machine.serial_number"),
        field: "serial_number",
        sort: "asc",
        width: 150,
        filter: true
      },
      {
        headerName: i18n.t("common.message"),
        field: "log_message",
        filter: true,
        width: 250,
        cellRendererFramework: "MachineMessageType"
      }
    ]
  }),
  watch: {
    status: function() {
      this.filterData();
    },
    "$store.state.windowWidth"(val) {
      this.resizeTable(val);
    },
    companyFilter: function() {
      this.filterData();
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 10;
    },
    totalPages() {
      if (this.gridApi) return this.gridApi.paginationGetTotalPages();
      else return 0;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        this.gridApi.paginationGoToPage(val - 1);
      }
    }
  },
  methods: {
    filterData() {
      this.data =
        this.companyFilter != ""
          ? this.dataRaw.filter(
              x =>
                x.current_company == this.companyFilter
            )
          : this.dataRaw;
    },
    resizeTable(width) {
      if (width <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("actions", null);
      } else {
        this.gridOptions.columnApi.setColumnPinned("actions", "right");
        this.gridOptions.api.sizeColumnsToFit();
      }
    },
    updateSearchQuery(val) {
      this.gridApi.setQuickFilter(val);
    },
    onCompanyFilterChange() {},
    loadCompanies() {
      let self = this;
      this.$store
        .dispatch("httpRequest", { method: "get", url: "company/all" })
        .then(data => {
          self.companyOptions = self.companyOptions.concat(
            data.filter(x => x.active)
          );
        })
        .catch(error => {
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    loadData() {
      var self = this;

      this.$vs.loading({
        container: "#list-content",
        scale: 0.5
      });

      this.$store
        .dispatch("httpRequest", { method: "get", url: "machine/logs/all" })
        .then(data => {
          self.$vs.loading.close("#list-content > .con-vs-loading");

          //separate each data for logs
          let dataLogs = [];
          data.forEach((machine) => {
            machine.logs.forEach((log) => {
              dataLogs.push({
                id: machine.id,
                serial_number: machine.serial_number,
                description: machine.description,
                type: machine.type,
                current_company: machine.current_company,
                log_message: log.message,
                log_category: log.category,
                log_date: log.date,
                nomeFantasia: machine.nomeFantasia.toUpperCase()
              });
            })
          })

          self.data = dataLogs;
          self.dataRaw = dataLogs;
        })
        .catch(error => {
          self.$vs.loading.close("#list-content > .con-vs-loading");
          this.$vs.notify({
            title: i18n.t("dialog.error"),
            text: error.message,
            color: "danger"
          });
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v =>
        filterVal.map(j => {
          return v[j];
        })
      );
    },
    exportToExcel() {
      import("@/vendor/Export2Excel").then(excel => {
        //const list = this.data

        //get only filtered data
        const list = [];
        this.gridOptions.api
          .getModel()
          .rootNode.childrenAfterFilter.forEach(x => {
            list.push(x.data);
          });

        const data = this.formatJson(this.headerVal, list);
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        });
        this.clearFields();
      });
    },
    clearFields() {
      (this.fileName = ""), (this.selectedFormat = "xlsx");
    }
  },
  mounted() {
    this.gridApi = this.gridOptions.api;
    if (this.$route.params.machine) {
      this.searchQuery = this.$route.params.machine.serial_number;
      this.gridApi.setQuickFilter(this.searchQuery);
    }

    if (this.$store.state.AppActiveUser.type > 0) {
      this.loadCompanies();
    }

    this.loadData();
    this.resizeTable(this.$store.state.windowWidth);
  }
};
</script>
